<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Convenios -->
            <v-col cols="12" class="py-0" md="6">
              <v-autocomplete
                class="mb-0 mt-0 pt-0"
                outlined
                dense
                v-model="conveniosSelect"
                label="Convenios"
                item-text="value"
                item-value="id"
                autocomplete="on"
                @change="setPlanesByConvenios(true)"
                :items="conveniosItems"
                :rules="rules.required"
                return-object
                clearable
              >
              </v-autocomplete>
            </v-col>
            <!-- Plan -->
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                outlined
                clearable
                dense
                item-text="value"
                item-value="id"
                return-object
                v-model="planesSelected"
                label="Planes"
                :rules="rules.required"
                :items="planesItems"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- Edad desde -->
            <v-col cols="12" class="py-0" md="4">
              <v-text-field
                type="text"
                outlined
                dense
                clearable
                label="Edad desde"
                v-mask="'###'"
                :rules="
                  rules.requiredAcceptZero.concat(
                    rules.positiveNumber,
                    [rules.maxNumber(edadHasta, 999)],
                    parseInt(edadDesde) <= parseInt(edadHasta) ||
                      'Rango invalido'
                  )
                "
                v-model.trim="edadDesde"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Edad hasta -->
            <v-col cols="12" class="py-0" md="4">
              <v-text-field
                type="text"
                outlined
                dense
                clearable
                v-mask="'###'"
                label="Edad hasta"
                :rules="
                  rules.requiredAcceptZero.concat(
                    rules.positiveNumber,
                    [rules.maxNumber(edadHasta, 999)],
                    parseInt(edadDesde) <= parseInt(edadHasta) ||
                      'Rango invalido'
                  )
                "
                v-model.trim="edadHasta"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Sexo -->
            <v-col cols="12" md="4" class="py-0">
              <v-autocomplete
                v-model="generoSelected"
                :items="generos"
                label="Sexo"
                item-text="value"
                item-value="value"
                :disabled="disabledSexo"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-radio-group
                @change="topesGralesItem == null ? changeSwitchs() : null"
                row
                v-model="grupoAndRango"
                mandatory
                class="py-0 mt-2"
              >
                <v-radio
                  class="py-0"
                  label="Rango de códigos"
                  value="R"
                ></v-radio>
                <v-radio
                  class="py-0"
                  label="Grupo de prácticas"
                  value="G"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <section v-if="grupoAndRango === 'R'">
            <v-row>
              <v-col cols="12" md="12" sm="12" class="py-0 mt-0">
                <v-card-title class="pl-0 pt-0"
                  ><b>Rango de códigos</b></v-card-title
                >
              </v-col>
            </v-row>
            <v-row>
              <!-- Tipo nomenclador -->
              <v-col cols="12" md="4" sm="4" class="py-0">
                <v-autocomplete
                  outlined
                  clearable
                  dense
                  :items="tipoNomencladorItems"
                  item-text="value"
                  item-value="id"
                  autocomplete="on"
                  label="Tipo nomenclador"
                  return-object
                  v-model="tipoNomencladorSelected"
                  :rules="this.grupoAndRango === 'R' ? rules.required : []"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <!-- codigo desde -->
              <v-col cols="3" md="4" class="py-0">
                <v-text-field
                  v-model.trim="codigoDesde"
                  label="Código desde"
                  outlined
                  clearable
                  dense
                  v-mask="'#########'"
                  :rules="
                    grupoAndRango === 'R'
                      ? rules.requiredAcceptZero.concat(
                          rules.positiveNumber,
                          parseInt(codigoDesde) <= parseInt(codigoHasta) ||
                            'Rango inválido'
                        )
                      : []
                  "
                  :disabled="tipoNomencladorSelected == null"
                  @change="findDescriptionByCode(true)"
                ></v-text-field>
              </v-col>
              <!-- Descripcion codigo desde -->
              <v-col cols="7" class="py-0" md="7">
                <v-text-field
                  v-model.trim="descCodigoDesde"
                  type="text"
                  outlined
                  filled
                  dense
                  disabled
                  label="Descripción código desde"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- Boton buscar codigo desde -->
              <v-col cols="2" md="1" class="py-0">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      absolute
                      fab
                      small
                      color="primary"
                      :disabled="tipoNomencladorSelected == null"
                      @click="openModalBusquedaCodigos(true)"
                    >
                      <v-icon>{{ searchIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Buscar "código desde" a través de la descripción</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <!-- codigo hasta -->
              <v-col cols="3" md="4" class="py-0">
                <v-text-field
                  v-model.trim="codigoHasta"
                  label="Código hasta"
                  outlined
                  clearable
                  v-mask="'#########'"
                  dense
                  :disabled="tipoNomencladorSelected == null"
                  :rules="
                    grupoAndRango === 'R'
                      ? rules.requiredAcceptZero.concat(
                          rules.positiveNumber,
                          Number(codigoDesde) <= Number(codigoHasta) ||
                            'Rango inválido'
                        )
                      : []
                  "
                  @change="findDescriptionByCode(false)"
                ></v-text-field>
              </v-col>
              <!-- Descripcion codigo hasta -->
              <v-col cols="7" class="py-0" md="7">
                <v-text-field
                  v-model.trim="descCodigoHasta"
                  type="text"
                  outlined
                  dense
                  filled
                  disabled
                  label="Descripción código hasta"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- Boton buscar codigo hasta -->
              <v-col cols="2" md="1" class="py-0">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      absolute
                      fab
                      small
                      color="primary"
                      :disabled="tipoNomencladorSelected == null"
                      @click="openModalBusquedaCodigos(false)"
                    >
                      <v-icon>{{ searchIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Buscar "código hasta" a través de la descripción</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </section>
          <section v-if="grupoAndRango === 'G'">
            <v-row>
              <v-col cols="12" md="12" sm="12" class="py-0">
                <v-card-title class="pl-0 pt-0"
                  ><b>Grupo de prácticas</b></v-card-title
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0 pt-0">
                <v-select
                  v-model="agruPracticasSelected"
                  :items="itemsAgruPracticas"
                  label="Agrupación de prácticas"
                  return-object
                  item-text="value"
                  item-value="id"
                  @change="setGrupos()"
                  outlined
                  :rules="
                    grupoAndRango === 'G' && agruPracticasSelected == null
                      ? ['Campo requerido']
                      : []
                  "
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="6" class="py-0 pt-0">
                <v-autocomplete
                  v-model="grupoPracticaSelected"
                  :items="itemsGrupoPractica"
                  label="Grupo de prácticas"
                  return-object
                  item-text="value"
                  :disabled="agruPracticasSelected == null"
                  item-value="id"
                  :rules="
                    grupoAndRango === 'G' && grupoPracticaSelected == null
                      ? ['Campo requerido']
                      : []
                  "
                  outlined
                  clearable
                  dense
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </section>
          <v-row>
            <!-- Fecha vigencia hasta-->
            <v-col cols="12" md="6" class="py-0">
              <v-menu
                ref="menu-fecha-vigencia"
                v-model="menuFechaVigencia"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaVigenciaSelected"
                    label="Vigencia hasta"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    outlined
                    clearable
                    dense
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    @blur="
                      fechaVigencia = parseDateToIso(fechaVigenciaSelected)
                    "
                    v-on="on"
                    :rules="rules.required.concat(rules.validDate)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaVigencia"
                  no-title
                  @change="fechaVigenciaSelected = formatDate(fechaVigencia)"
                  @input="menuFechaVigencia = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Tope -->
            <v-col cols="12" class="py-0" md="3">
              <v-text-field
                type="text"
                outlined
                dense
                clearable
                label="Tope"
                v-model.trim="tope"
                autocomplete="off"
                v-mask="'###'"
                :rules="rules.required.concat(rules.maxLength(tope, 3))"
              ></v-text-field>
            </v-col>
            <!-- Dias -->
            <v-col cols="12" class="py-0" md="3">
              <v-text-field
                outlined
                dense
                clearable
                label="Días"
                v-model.trim="dias"
                v-mask="'#####'"
                :rules="
                  rules.required.concat([rules.minMaxNumber(dias, 0, 36500)])
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Tipo de autorizacion -->
            <v-col cols="12" md="12" class="py-0">
              <v-autocomplete
                outlined
                clearable
                dense
                :items="tiposAutorizacionItems"
                item-text="value"
                item-value="id"
                autocomplete="on"
                label="Acción por exceso del tope"
                return-object
                :rules="rules.required"
                v-model="tipoAutorizacionSelect"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- switch tope por año calendario -->
            <v-col cols="12" md="4" class="py-0">
              <v-switch
                class="ml-4 mt-3"
                v-model="topePorAno"
                label="Tope por año calendario."
              ></v-switch>
            </v-col>
            <!-- switch controlar codigos del rango -->
            <v-col v-if="canSeeControlarRango" cols="12" md="4" class="py-0">
              <v-switch
                class="ml-4 mt-3"
                v-model="controlarRango"
                :disabled="grupoAndRango === 'G'"
                label="Controlar todos los códigos del rango."
              ></v-switch>
            </v-col>
            <v-col
              v-if="canSeeAplicarTopePorGrupo"
              cols="12"
              md="4"
              class="py-0"
            >
              <v-switch
                class="ml-4 mt-3"
                v-model="aplicarTopeGrupoFamiliar"
                label="Aplicar tope al grupo familiar."
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          :loading="loadingSaveBtn"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
    <!-- modal para buscar codigo desde -->
    <v-dialog
      v-model="modalBusquedaCodigos"
      v-if="modalBusquedaCodigos"
      @keydown.esc="modalBusquedaCodigos = !modalBusquedaCodigos"
      max-width="50%"
      persistent
    >
      <BusquedaCodigoNomenclador
        @toggleModalBusquedaCodigo="
          modalBusquedaCodigos = !modalBusquedaCodigos
        "
        @findCodigo="findCodigo"
        :nomencladorObject="tipoNomencladorSelected"
      ></BusquedaCodigoNomenclador>
    </v-dialog>
    <ConfirmDialog
      :text="titleConfirmSave"
      :openConfirm.sync="openConfirmSave"
      @update:openConfirm="cancelSaveTope()"
      @onConfirm="saveEdit(true)"
    />
  </v-card>
</template>
<script>
import BusquedaCodigoNomenclador from "@/components/modules/afiliaciones/afiliados/BusquedaCodigoNomenclador.vue";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  directives: { mask },
  name: "EditTopesCoberturaEspecial",
  props: {
    topesGralesItem: {
      type: Object,
      default: null
    }
  },
  components: {
    BusquedaCodigoNomenclador,
    ConfirmDialog
  },
  data() {
    return {
      rules: rules,
      formEditTitle: "Nuevo tope general",
      calendarIcon: enums.icons.CALENDAR,
      searchIcon: enums.icons.SEARCH,
      edadDesde: null,
      edadHasta: null,
      codigoDesde: null,
      codigoHasta: null,
      tope: null,
      modalBusquedaCodigos: false,
      grupoAndRango: null,
      descCodigoDesde: null,
      descCodigoHasta: null,
      dias: null,
      generoSelected: null,
      generos: [{ value: "M" }, { value: "F" }, { value: "Ambos" }],
      tipoNomencladorSelected: null,
      tipoNomencladorItems: [],
      conveniosSelect: null,
      conveniosItems: [],
      planesSelected: null,
      planesItems: [],
      agruPracticasSelected: null,
      grupoPracticaSelected: null,
      itemsGrupoPractica: [],
      itemsAgruPracticas: [],
      tipoAutorizacionSelect: null,
      tiposAutorizacionItems: [],
      menuFechaVigencia: null,
      fechaVigencia: null,
      fechaVigenciaSelected: null,
      controlarRango: false,
      topePorAno: false,
      isFormValid: false,
      loadingSaveBtn: false,
      isFindCodigoDesde: false,
      canSeeControlarRango: false,
      canSeeAplicarTopePorGrupo: false,
      aplicarTopeGrupoFamiliar: false,
      openConfirmSave: false,
      disabledSexo: false,
      titleConfirmSave:
        "Los rangos de edad o códigos se superponen con los datos cargados. ¿Desea continuar de todas formas?"
    };
  },
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    await this.setSelects();
    if (this.topesGralesItem) this.setTopeGenerales();
    else {
      this.conveniosSelect = { id: 0, value: "Todos" };
      this.planesSelected = { id: -1, value: "Todos" };
      this.generoSelected = { value: "Ambos" };
    }
  },
  methods: {
    ...mapActions({
      getParametrosGralesTopes: "prestaciones/getParametrosGralesTopes",
      getConvenios: "aportes/fetchConvenios",
      getPlanesByConvenio: "configAfiliaciones/getPlanesByConvenio",
      getPlanesTaxonomy: "configAfiliaciones/getPlanesTaxonomy",
      getTiposAutorizacionesTaxonomy:
        "configAfiliaciones/getTiposAutorizacionesTaxonomy",
      getNomencladores: "afiliaciones/getNomencladores",
      getDescByNomencladorYCodigo: "afiliaciones/getDescByNomencladorYCodigo",
      saveTopeGrupoPractica: "prestaciones/saveTopeGrupoPractica",
      getGrupoPorPractica: "analytics/getGrupoPorPractica",
      getAgrupacionesPracticas: "analytics/getAgrupacionesPracticas",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const parametrosGrales = await this.getParametrosGralesTopes();
      this.canSeeAplicarTopePorGrupo =
        parametrosGrales.permitirAplicarTopePorGrupo;
      this.canSeeControlarRango = parametrosGrales.controlarTopesPorRangoCodigo;
      const convenios = await this.getConvenios();
      this.conveniosItems = convenios;
      this.conveniosItems.unshift({ id: 0, value: "Todos" });
      const planes = await this.getPlanesTaxonomy();
      this.planesItems = planes;
      this.planesItems.unshift({ id: -1, value: "Todos" });
      const tiposAutorizacion = await this.getTiposAutorizacionesTaxonomy();
      this.tiposAutorizacionItems = tiposAutorizacion.filter(objeto => {
        return objeto.id === 2 || objeto.id === 4;
      });
      const nomencladores = await this.getNomencladores();
      this.tipoNomencladorItems = nomencladores;
      const agrupaciones = await this.getAgrupacionesPracticas();
      this.itemsAgruPracticas = agrupaciones;
    },
    setTopeGenerales() {
      this.disabledSexo = true;
      this.formEditTitle = "Editar tope general";
      if (this.topesGralesItem.nomenclador !== null) this.grupoAndRango = "R";
      if (
        this.topesGralesItem.gruId !== null &&
        this.topesGralesItem.agrupra !== null
      ) {
        this.grupoAndRango = "G";
        this.agruPracticasSelected = this.topesGralesItem.agrupra;
        this.setGrupos();
        this.grupoPracticaSelected = this.topesGralesItem.gru;
      }
      this.conveniosSelect = this.topesGralesItem.os;
      this.setPlanesByConvenios();
      this.planesSelected = this.topesGralesItem.plan;
      this.edadDesde = this.topesGralesItem.edadDesde;
      this.aplicarTopeGrupoFamiliar = this.topesGralesItem.aplicarTopePorGrupo;
      this.edadHasta = this.topesGralesItem.edadHasta;
      this.generoSelected = this.topesGralesItem.sexo;
      this.tipoNomencladorSelected = this.topesGralesItem.nomenclador;
      this.codigoDesde = this.topesGralesItem.codigoDesde;
      this.codigoHasta = this.topesGralesItem.codigoHasta;
      this.fechaVigenciaSelected = this.topesGralesItem.vigencia;
      this.fechaVigencia = this.parseDateToIso(this.topesGralesItem.vigencia);
      this.tope = this.topesGralesItem.tope.toString();
      this.dias = this.topesGralesItem.dias.toString();
      this.tipoAutorizacionSelect = this.topesGralesItem.tipoAutorizacion;
      this.controlarRango = this.topesGralesItem.controlarCodRango;
      this.topePorAno = this.topesGralesItem.anioCalendario;
      this.findDescriptionByCode(true);
      this.findDescriptionByCode(false);
    },
    async setPlanesByConvenios(ban) {
      if (
        this.conveniosSelect?.id == 0 ||
        this.conveniosSelect?.value == "Todos"
      ) {
        const planes = await this.getPlanesTaxonomy();
        this.planesItems = planes;
      } else {
        const planesByConv = await this.getPlanesByConvenio([
          this.conveniosSelect.id
        ]);
        this.planesItems = planesByConv;
      }
      this.planesItems.unshift({ id: -1, value: "Todos" });
      if (ban) this.planesSelected = null;
    },
    changeSwitchs() {
      if (this.grupoAndRango === "R") {
        this.grupoPracticaSelected = null;
        this.agruPracticasSelected = null;
      }
      if (this.grupoAndRango === "G") {
        this.tipoNomencladorSelected = null;
        this.codigoDesde = null;
        this.codigoHasta = null;
        this.descCodigoDesde = null;
        this.descCodigoHasta = null;
      }
    },
    async setGrupos() {
      this.grupoPracticaSelected = null;
      if (this.agruPracticasSelected != null) {
        const grupo = await this.getGrupoPorPractica(
          this.agruPracticasSelected.id
        );
        this.itemsGrupoPractica = grupo;
      }
    },
    cancelSaveTope() {
      this.loadingSaveBtn = false;
      this.openConfirmSave = false;
    },
    openModalBusquedaCodigos(isCodigoDesde) {
      if (isCodigoDesde) this.isFindCodigoDesde = true;
      else this.isFindCodigoDesde = false;
      this.modalBusquedaCodigos = true;
    },
    findCodigo(codigoSelected) {
      if (this.isFindCodigoDesde) {
        this.codigoDesde = codigoSelected.nomCod;
        this.descCodigoDesde = codigoSelected.nombre;
      } else {
        this.codigoHasta = codigoSelected.nomCod;
        this.descCodigoHasta = codigoSelected.nombre;
      }
      this.isFindCodigoDesde = false;
    },
    async findDescriptionByCode(isCodigoDesde) {
      if (isCodigoDesde) {
        if (this.codigoDesde) {
          const data = {
            nomenclador: this.tipoNomencladorSelected.id,
            codigo: this.codigoDesde
          };
          const descripcionCodigoDesde = await this.getDescByNomencladorYCodigo(
            data
          );
          this.descCodigoDesde = descripcionCodigoDesde.nombre;
        } else this.descCodigoDesde = null;
      }
      if (!isCodigoDesde) {
        if (this.codigoHasta) {
          const data = {
            nomenclador: this.tipoNomencladorSelected.id,
            codigo: this.codigoHasta
          };
          const descripcionCodigoHasta = await this.getDescByNomencladorYCodigo(
            data
          );
          this.descCodigoHasta = descripcionCodigoHasta.nombre;
        } else this.descCodigoHasta = null;
      }
    },
    async saveEdit(ban) {
      this.loadingSaveBtn = true;
      const data = {
        tautoBenId: this.topesGralesItem?.tautoBenId,
        osId: this.conveniosSelect?.id,
        planId: this.planesSelected?.id ?? -1,
        nomencladorId: this.tipoNomencladorSelected?.id,
        codigoDesde: this.codigoDesde,
        codigoHasta: this.codigoHasta,
        sexo:
          this.generoSelected.value === "Ambos" ? null : this.generoSelected,
        edadDesde: this.edadDesde,
        edadHasta: this.edadHasta,
        vigencia: this.fechaVigencia,
        tope: parseInt(this.tope),
        dias: this.dias,
        tipoAutorizacionId: this.tipoAutorizacionSelect.id,
        anioCalendario: this.topePorAno,
        controlarCodRango: this.controlarRango,
        agruPraId: this.agruPracticasSelected?.id,
        gruId: this.grupoPracticaSelected?.id,
        aplicarTopePorGrupo: this.aplicarTopeGrupoFamiliar,
        validated: ban == undefined ? false : ban
      };
      try {
        const res = await this.saveTopeGrupoPractica(data);
        //error de superposicion de codigos o edad
        if (res === "Existe superposición de rangos.") {
          this.openConfirmSave = true;
        }
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
        }
      } catch (error) {}
      this.loadingSaveBtn = false;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }
  }
};
</script>
