<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <!-- Tipo nomenclador -->
          <v-col cols="12" md="4" sm="4" class="py-0">
            <v-autocomplete
              outlined
              clearable
              dense
              :items="tipoNomencladorItems"
              item-text="value"
              item-value="id"
              autocomplete="on"
              label="Tipo nomenclador"
              return-object
              v-model="tipoNomencladorSelected"
              @change="setConfig()"
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="codigoSinConfigurar"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
        </v-data-table>
        <v-card-actions class="p-0 mt-3">
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModal"> Cancelar </v-btn>
          <v-btn
            :loading="loadingSaveBtn"
            color="primary"
            @click="exportExcel()"
          >
            Exportar
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-container>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import helpersExcel from "@/utils/helpers/importExportExcel";
export default {
  directives: { mask },
  name: "CodigoSinConfigurarExcel",
  props: {
    topesGralesItem: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      rules: rules,
      search: "",
      searchIcon: enums.icons.SEARCH,
      loadingSaveBtn: false,
      isLoading: false,
      title: "Códigos sin configurar",
      tipoNomencladorSelected: null,
      tipoNomencladorItems: [],
      resultsExportExcel: [],
      codigoSinConfigurar: [],
      headers: [
        {
          text: "Tipo Nomenclador",
          align: "start",
          value: "tipoNomenclador",
          sortable: false
        },
        {
          text: "Código de nomenclador",
          align: "start",
          value: "nomCod",
          sortable: false
        },
        {
          text: "Nombre de nomenclador",
          align: "start",
          value: "nomNom",
          sortable: false
        }
      ]
    };
  },
  created() {
    this.topesGralesItem;
    this.setSelects();
  },
  methods: {
    ...mapActions({
      getNomencladores: "afiliaciones/getNomencladores",
      getCodigosSinConfigurarTopes: "prestaciones/getCodigosSinConfigurarTopes",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const nomencladores = await this.getNomencladores();
      this.tipoNomencladorItems = nomencladores;
    },
    async setConfig() {
      this.isLoading = true;
      this.loadingSaveBtn = true;
      this.codigoSinConfigurar = [];
      try {
        const configuraciones = await this.getCodigosSinConfigurarTopes(
          this.tipoNomencladorSelected.id
        );

        this.codigoSinConfigurar = configuraciones;
        this.resultsExportExcel = configuraciones;
        this.isLoading = false;
        this.loadingSaveBtn = false;
      } catch {
        this.isLoading = false;
        this.loadingSaveBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    exportExcel() {
      let result = [];
      this.resultsExportExcel.forEach(x =>
        result.push({
          ["Tipo Nomenclador"]: x.tipoNomenclador,
          ["Codigo de nomenclador"]: x.nomCod,
          ["Nombre de nomenclador"]: x.nomNom
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(
        formato,
        "Detalle de códigos sin configurar topes"
      );
      this.setAlert({ type: "success", message: "Exportado con éxito." });
      this.toggleModalExportarExcel();
      this.closeModal();
    }
  }
};
</script>
